import React, { useState } from "react";
import { lightPinkSunLogo } from "../assets";
import Slideshow from "../components/Slideshow";
import HomeWTFM from "../components/HomeWTFM";
import Banner from "../components/Banner";
import HomePPCarousel from "../components/HomePPCarousel";
import { Link } from "react-router-dom";
import ProductSwiper from "../components/ProductSwiper";
import { ToastContainer } from "react-toastify";
import UpcomingEvent  from "../components/UpcomingEvent";
import "./home.css";

import {
  ellissahome,
  learnmorewhite,
  purplelearnmorebtn,
  littlewhitearrow,
} from "../photos/notBeadwork";

const Home = () => {

  const [userID] = useState(null);

  return (
    <div class="md:mt-28 mt-14">
      <div class=" bg-[#f8f1f7] mx-auto ">
        <div className="w-full sqSlideshow relative">
          <Slideshow />
        </div>
        <div className="wideBanner">
          <Banner />
        </div>

        <div className="welcome blackest text-center px-1  md:pt-20 max-w-[425px]  sm:order-1 md:order-1 lg:order-1 ">
          <h1 className=" md:text-4xl lg:text-4xl">IHOO TALOOWA</h1>
          <img
            className="py-2 w-14 mx-auto md:w-20"
            alt="ihootaloowa"
            src={lightPinkSunLogo}
          />
          <h1 className="md:text-4xl lg:text-4xl">SINGING WOMAN</h1>
          <h6 className="">
            Authentic Native American beadwork handmade by Ellissa Thompson
          </h6>
        </div>
      </div>

      {/**  PRODUCTS MAP */}

      <div className=" text-[#90788d] mx-auto bg-white  text-center my-4 px-[6vw] md:px-4 ">
        <div className="max-w-[1200px] mx-auto">
          <ToastContainer
            position="top-left"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ProductSwiper userID={userID} />
        </div>

        <Link to="/shop" className="no-underline ">
          <div className="text-2xl lg:text-3xl text-[#6e554b] blackest  w-[6rem] mx-auto hover:text-[#8d6464]">
            SHOP ALL
          </div>
          <img
            src={littlewhitearrow}
            className="w-[6rem]  mx-auto"
            alt="Shop modern native american beaded jewelry"
          />
        </Link>
      </div>



      {/** COME SEE US  3 IMAGE SWIPERbg-[#dfd2d6] */}

      <div class=" bg-[#dccada] xl:pb-8 pt-2 mx-auto text-center quattrocento  text-[#6e5a67]">
        <HomeWTFM />
        <UpcomingEvent />

        {/*<div class="col-span-2 py-8 text-center lg:max-w-[650px]  mx-auto   ">
          <h1 className="blackest md:text-4xl lg:text-4xl text-[#664A2C]">
            COME SEE US!
          </h1>
          <p className=" text-sm md:text-lg">
            Find Ihoo Taloowa beadwork at upcoming Powwows, markets, and other
            events
          </p>
        </div>*/}
      </div>



      {/** ABOUT THE ARTIST SECTION */}

      <div class="quattrocento bg-[#F2EFEB] ata">
        <div class="grid sm:grid-cols-2 sm:gap-6 mx-auto max-w-5xl items-center pb-5 px-2 md:px-0 align-middle">
          <div className="w-full mx-auto">
            <img
              src={ellissahome}
              className="w-[90vw] md:max-h-[650px] h-auto mx-auto"
              alt="meet the artist"
            />
          </div>
          <div className="w-full px-6  text-center pt-8">
            <h1 className="blackest md:text-4xl lg:text-4xl text-[#664A2C]">
              INDIGENOUS WOMAN OWNED
            </h1>
            <p className="text-left md:text-center text-sm md:text-lg text-[#585656]">
              Ellissa K. Thompson is a 25 year old bay-area native with roots in
              the chickasaw nation. She created Ihoo Taloowa 4 years ago and
              strives to create contemporary beadwork with traditional values.
            </p>
            <Link
              to="/AboutTheArtist"
              className=" hover:border-1 hover:border-[#585656]"
            >
              <img
                src={learnmorewhite}
                className="md:w-[8rem] w-[6rem] mx-auto"
                alt="Native American Women Owned Business"
              />
            </Link>
          </div>
        </div>
      </div>

      {/** PAST PROJECTS */}

      <div class="pb-2 w-full bg-[#ad919c]">
        <div class="py-6 pl-1 blackest  text-[#4c3341] ">
          <h1 className="md:text-4xl lg:text-4xl mb-0">
            BROWSE BEADWORK CRAFTED OVER THE LAST 4 YEARS
          </h1>
          <p className="text-md quattrocento ">
            hoops | dangles | flatwork | collars | chokers | fringe | medallions
            | and more!
          </p>
          <a
            className=" text-[#4c3341] hover:text-[#e9d7d7] hover:bg-[#4c3341] border-[#4c3341] p-2 border-2 text-xl no-underline"
            href="/PastProjects"
          >
            PAST PROJECTS
          </a>
        </div>

        <HomePPCarousel />
      </div>
    </div>
  );
};



export default Home;
