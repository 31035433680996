import React from 'react';
import { Link } from 'react-router-dom';
import { WhereToFindMe } from '../pages/WhereToFindMe';
import {
  emptyPurpleSunSquare,
  emptyPurpleSunBanner,
  purplelearnmorebtn,
  littlepurplearrow,
} from '../photos/notBeadwork';
import "./Subscribe.css";

const UpcomingEvent = () => {
  return (
    <div>
        
        <div className='subscribe update bg-[#dccada] lg:py-6 md:py-12 text-[#664A2C] '>
            <div className="heart md:py-0 text-center">
                <img className='update-hearttemps mx-auto' src={emptyPurpleSunSquare} alt='Subscribe! Chickasaw made, Bay raised'/>
                <img className='update-hearttemps2 ml-auto ' src={emptyPurpleSunBanner} alt='Subscribe! Chickasaw made, Bay raised'/>
                <div className='centered-text  update-centered-text '>
                  <div className='mx-auto update-text lg:mb-4'>
                    <h1 className='pb-[3vw] lg:pb-0'>UPCOMING EVENTS </h1>
                    
                      <span className='text-3xl'>9/14 - Good Medicine </span><br></br>
                      <span className='text-xl lg:text-2xl'>A Night of Native Stand-Up</span><br></br>
                      <span className='text-md '>Oakland Museum of California </span><br></br>
                      <span className='text-md '>6:00pm - 10:00pm </span>
      
                    
                  </div>
                  <Link to="/WhereToFindMe" className='text-[#664A2C] no-underline'>
                    <h2 className='max-w-[96px] mx-auto mt-3 mb-0 pb-0'>LEARN MORE</h2>
                    <img
                      src={littlepurplearrow}
                      className="md:w-[8rem] wtfm-btn w-[6rem] mx-auto mt-0 pt-0"
                      href="/AboutTheArtist"
                      alt="Native American Women Owned Business"
                    />
                  </Link>
                </div>

            </div>
        </div>
    </div>
  )
}

export default UpcomingEvent;