import React from 'react';


import 'swiper/css';
import 'swiper/css/free-mode';

import 'bootstrap/dist/css/bootstrap.min.css';

import { 
  may5,
  arrowBackToShop,
  goodMedicine2024
 } from '../photos/notBeadwork';
import 'swiper/css';
import 'swiper/css/free-mode';

import './WTFM.css';

const WhereToFindMe = () => {
  return (
    <div className=' bg-[#F2EFEB]'>

      <div class="md:mt-28 md:pt-12 mt-14 pt-8 mx-auto text-center ">

        
        
        <h1 className='text-[#8d6464] blackest'>WHERE TO FIND ME</h1>
        <div class=" mx-auto  py-4 text-[#594242] quattrocento max-w-6xl">

              <div className='mx-auto update-text'>
                    
                    <span className='text-3xl'>9/14 GOOD MEDICINE </span><br></br>
                    <p className='text-2xl'>A Night of Native Stand-Up </p>
                    <span className='xl:text-xl text-md'>Oakland Museum of California </span><br></br>
                    <span className='xl:text-xl text-md'>1000 Oak St Oakland, CA 94607</span><br></br>
                    <p className='xl:text-xl text-md'>6:00pm - 10:00pm</p>
                    <a className="" href="https://museumca.org/event/good-medicine-a-night-of-live-native-stand-up-24/">
                      <button className="border-1 text-xl border-[#7f5454] text-[#594242] py-1 px-2  mb-4 hover:bg-[#e6cece]">
                        TICKETS
                      </button>
                    </a><br></br>
                  
                    
              </div>

              <h1 className='text-[#8d6464] blackest pb-4'>Check back for more info!</h1>
                
            </div>

        {/**<h2 className='text-[#8d6464] blackest max-w-[230px] mx-auto pt-20 md:pt-16 '>NO UPCOMING EVENTS</h2>
        <a href="/Shop" >
          <img src={arrowBackToShop} alt='about the artist' className="w-[225px] mx-auto pb-28 md:pb-24" />
  </a>*/}

        <div className="bg-[#e8d7d7] w-[100vw] pt-8 md:pt-12">

          <h1 className='text-[#8d6464] blackest'>PAST EVENTS</h1>

          <div class=" mx-auto  pt-4 text-[#594242] quattrocento max-w-6xl">

            <div class="  grid gap-4 md:grid-cols-2 md:pb-8 lg:pb-8 sm:gap-6">

              <div class="w-full md:order-2 lg:order-2 items-center   max-w-100">
                <img src={may5} alt='about the artist' className="w-100" />   
              </div>

              <div class="w-full h-w self-center pb-4 lg:px-8 md:order-1 lg:order-1 md:justify-center">
                <h3 className='text-center text-[#8d6464]'>MAY 5TH 2024 </h3>
                <h1 className='text-center '>SPRING DANCE </h1>
                <h4 className='text-center '>AMERICAN INDIAN CULTURAL CENTER OF SAN FRANCISCO</h4>
                <p> 
                  10am - 5pm <br></br>
                  Main Parade Ground Lawn<br></br>
                  Montgomery St.<br></br>
                  San Francisco, CA<br></br>
                </p>
                <p>
                  Find Ihoo Taloowa vending!
                </p>   
              </div>
                
              </div>
            </div>
          </div>

        </div>
      </div>
   


  )
}

export default WhereToFindMe;